import Link from 'next/link';
import { MouseEventHandler } from 'react';

import styles from 'styles/Layout/Navbar.module.css';

import { MenuType } from '@/types';

type MenuProps = Omit<MenuType, 'id'> & {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
};

export default function Menu({
  text,
  link,
  target,
  submenus,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: MenuProps) {
  return (
    <div
      className={styles.menu}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {submenus?.length ? (
        <p className={`${styles.menu__link} ${styles.has__submenu}`}>{text}</p>
      ) : (
        <Link href={link}>
          <a
            target={target === 'Mesma aba' ? '_self' : '_blank'}
            className={styles.menu__link}
          >
            {text}
          </a>
        </Link>
      )}
    </div>
  );
}
