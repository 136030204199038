import Link from 'next/link';
import { useReservationBar } from 'contexts/reservationBar';
import CheckIcon2 from 'public/icons/progress-step-done.svg';
import CurrentIcon from 'public/icons/progress-step-current.svg';
import ToDoIcon from 'public/icons/progress-step-todo.svg';
import styles from 'styles/ReservationBar.module.css';
import ReservationBarProgress from './ReservationBarProgress';
import { useRouter } from 'next/router';

export default function ReservationBar({ max }: { max: number }) {
  const {
    reservationBar: { title, progress, step, values },
  } = useReservationBar();

  const router = useRouter();

  // Adjust the step based on the current route
  const currentStep = router.pathname.startsWith('/pagamento') || router.pathname.startsWith('/reserva') ? 5 : step;

  const objectToQuery = (object: any): string => {
    return Object.keys(object).map(key => {
      if (key === 'extras') {
        if (Array.isArray(object.extras)) {
          return object.extras.map((extra: any) => `extras=${encodeURIComponent(extra)}`).join('&');
        }
      }

      return `${key}=${encodeURIComponent(object[key])}`;
    }).join('&');
  }

  const stepsData = [
    { label: 'Itinerário', query: '/', minStep: 1 },
    { label: 'Veículo', query: '/veiculos', minStep: 2 },
    { label: 'Extras', query: '/extras', minStep: 3 },
    { label: 'Condutor', query: '/extras', minStep: 4 },
    { label: 'Confirmação', query: '/extras', minStep: 5 },
  ]

  const ancorProgressBar = (query: any) => {
    router.push(`${query}?${objectToQuery(router.query)}`)
  }

  return (
    <div className={styles.reservationBar}>
      {/* <div className={styles.reservationBar__content_mobile}>
        <h3 className={styles.content__title}>{title}</h3>
        <ReservationBarProgress step={currentStep} progress={progress} max={max} />
      </div> */}
      <div className={styles.reservationBar__content_desktop}>
        <div className={styles.reservationBar__steps}>
          {stepsData.map(({ label, query, minStep }, index) => (
            <div
              key={index}
              className={`
                          ${styles.reservationBar__step} 
                          ${currentStep > minStep && styles.reservationBar__step_active}
                          ${currentStep == minStep && styles.reservationBar__step_left}`}
            >
              <h3
                className={`
                            ${styles.reservationBar__step__title} 
                            ${currentStep >= minStep && styles.active} 
                            ${currentStep > minStep && styles.hasValue}`}
              >
                {label}
              </h3>
              {currentStep > minStep && (
                <Link href={`${query}?${objectToQuery(router.query)}`}>
                  <a className={styles.reservationBar__step__subtitle}>Editar</a>
                </Link>
              )}
              {currentStep > minStep && <CheckIcon2 onClick={() => ancorProgressBar(query)} className={styles.icon} />}
              {currentStep === minStep && <CurrentIcon className={styles.icon} />}
              {currentStep < minStep && <ToDoIcon className={`${styles.icon} ${styles.toDo__icon}`} />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
