import Head from 'next/head';
import Image from 'next/image';
import {
  useState,
  useEffect,
  PropsWithChildren,
  MouseEventHandler,
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import Breadcrumb from 'components/Breadcrumb';
import ReservationBar from 'components/ReservationBar';
import { useLayout } from 'contexts/layout';
import { ReservationBarProvider } from 'contexts/reservationBar';
import { motion, AnimatePresence } from 'framer-motion';
import styles from 'styles/Layout/Layout.module.css';

import Navbar from './Navbar';
import { local } from '@/api/api';
import { MenuType } from '@/types';
import { useRouter } from 'next/router';

export type LayoutProps = PropsWithChildren & { page: string };

const variants = {
  hidden: { opacity: 0, x: -200, y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 0, x: 0, y: -100 },
};

const override = {
  display: 'block',
  borderColor: 'var(--primary)',
};

export default function Layout({ children, page }: LayoutProps) {
  const { layout } = useLayout();
  const router = useRouter();

  const [menus, setMenus] = useState<MenuType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      try {
        const {
          data: { menus },
        } = await local('/api/settings');
        setMenus(menus);
      } catch (err) {
      } finally {
        setTimeout(() => {
          setIsLoading(false);
        }, 700);
      }
    }

    fetch();
  }, []);

  const close = () => {
    setIsOpen(false);
  };

  const toggle: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Head>
        <title>
          {process.env.NEXT_PUBLIC_HEAD_TITLE ||
            'Configurar reserva / Não esqueça de reservar'}
        </title>
        <meta
          name="description"
          content={
            process.env.NEXT_PUBLIC_HEAD_DESC ||
            'A Foco é uma empresa de aluguel de carros para o turismo de negócios e lazer. Aqui você aluga veículos novos com os melhores preços.'
          }
        />
        <meta
          name="tags"
          content={
            process.env.NEXT_PUBLIC_HEAD_KEYWORDS ||
            'Alugue Foco, Aplicativos, Motorista de Aplicativos, Reserva, Aluguel de Carros'
          }
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className={styles.layout}>
        <AnimatePresence mode="wait">
          {isLoading ? (
            <motion.div
              key="loading"
              className={styles.loading}
              initial={{ opacity: 0, scale: 0.5 }}
              exit={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <Image
                  priority
                  layout="fixed"
                  width={154}
                  height={70}
                  src="/img/logo.png"
                  alt="Alugue Foco"
                />
              </div>
              <div className={styles.loading__content}>
                <ClipLoader
                  color="#f20000"
                  loading={isLoading}
                  cssOverride={override}
                  size={30}
                />
                <p>Carregando...</p>
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="content"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Navbar
                isOpen={isOpen}
                close={close}
                toggle={toggle}
                menus={menus}
                setMenus={setMenus}
              />
              <div onClick={close} className={styles.main__container}>
                <Breadcrumb items={layout.breadcrumbs} />
                <h2 className={styles.content__title}>Reservas Online</h2>
                <ReservationBarProvider>
                  <ReservationBar max={5} />
                  <motion.div
                    key={page}
                    variants={variants}
                    initial="hidden"
                    animate="enter"
                    exit="exit"
                    transition={{ type: 'linear' }}
                  >
                    {children}
                  </motion.div>
                </ReservationBarProvider>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
}
