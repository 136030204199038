import Link from 'next/link';

import styles from 'styles/Breadcrumb.module.css';

type ItemType = {
  active: boolean;
  href: string;
  label: string;
};

type BreadcrumbProps = {
  items: ItemType[];
};

export default function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <div className={styles.breadcrumb}>
      <div className={styles.breadcrumb__content}>
        <ul className={styles.breadcrumb__list}>
          <li className={styles.list__item}>
            <Link href="https://aluguefoco.com.br">Página inicial</Link>
          </li>
          <li className={styles.list__item}>Reservas online</li>
          {items.map((item, key) => {
            return item.active ? (
              <li key={key} className={styles.list__item}>
                {item.label}
              </li>
            ) : (
              <li key={key} className={styles.list__item}>
                <Link href={item.href}>
                  <a>{item.label}</a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
